dl, ol, p, pre, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

address, dl, ol, p, pre, ul {
  margin-bottom: 1rem; }

img, svg {
  vertical-align: middle; }

body, caption {
  text-align: left; }

dd, h1, h2, h3, h4, h5, h6, label {
  margin-bottom: .5rem; }

pre, textarea {
  overflow: auto; }

article, aside, figcaption, figure, footer, header, hgroup, legend, main, nav, section {
  display: block; }

address, legend {
  line-height: inherit; }

progress, sub, sup {
  vertical-align: baseline; }

label, output {
  display: inline-block; }

button, hr, input {
  overflow: visible; }

.fdb-block, .fdb-block .container {
  background-position: center;
  background-repeat: no-repeat; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-family: Lato,Helvetica,Arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

.btn:not(:disabled):not(.disabled), summary {
  cursor: pointer; }

address {
  font-style: normal; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

blockquote, figure {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #329ef7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em; }

pre {
  -ms-overflow-style: scrollbar; }

img {
  border-style: none; }

svg {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  caption-side: bottom; }

th {
  text-align: inherit; }

button {
  border-radius: 0; }

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, select {
  text-transform: none; }

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0; }

input[type=datetime-local], input[type=month], input[type=date], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  color: inherit;
  white-space: normal; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: inherit; }

.blockquote, hr {
  margin-bottom: 1rem; }

.h1, h1 {
  font-size: 2.75rem; }

.h2, h2 {
  font-size: 2rem; }

.h3, h3 {
  font-size: 1.125rem; }

.h4, h4 {
  font-size: 1rem; }

.h5, h5 {
  font-size: .875rem; }

.h6, h6 {
  font-size: .813rem; }

.lead {
  font-size: 1.4rem;
  font-weight: 400; }

.display-1, .display-2, .display-3, .display-4 {
  font-weight: 300;
  line-height: 1.2; }

.display-1 {
  font-size: 6rem; }

.display-2 {
  font-size: 5.5rem; }

.display-3 {
  font-size: 4.5rem; }

.display-4 {
  font-size: 3.5rem; }

.btn, .btn-link, .small, p, small {
  font-weight: 400; }

hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.fdb-block, .rounded {
  overflow: hidden; }

.small, small {
  font-size: 80%; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014 \00A0"; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + .25rem);
  padding: .375rem .75rem;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.custom-file-input.is-valid ~ .custom-file-label, .custom-select.is-valid, .form-control.is-valid, .was-validated .custom-file-input:valid ~ .custom-file-label, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + .25rem);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.form-control-lg {
  height: calc(2.875rem + .25rem);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: .25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

.invalid-tooltip, .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  max-width: 100%;
  padding: .25rem .5rem;
  border-radius: .25rem;
  line-height: 1.5; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  display: none;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9); }

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label, .form-check-input.is-valid ~ .form-check-label, .was-validated .custom-control-input:valid ~ .custom-control-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-file-input.is-valid ~ .custom-file-label::after, .was-validated .custom-file-input:valid ~ .custom-file-label::after {
  border-color: inherit; }

.custom-file-input.is-invalid ~ .custom-file-label, .custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-file-input:invalid ~ .custom-file-label, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545; }

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  display: none;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label, .form-check-input.is-invalid ~ .form-check-label, .was-validated .custom-control-input:invalid ~ .custom-control-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-file-input.is-invalid ~ .custom-file-label::after, .was-validated .custom-file-input:invalid ~ .custom-file-label::after {
  border-color: inherit; }

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .custom-select, .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn-block, input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: .125rem solid transparent;
  padding: .375rem 1.625rem;
  font-size: 1em;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:focus, .btn:hover {
  text-decoration: none; }

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 158, 247, 0.5); }

.btn.disabled, .btn:disabled {
  opacity: .65; }

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7; }

.btn-primary:hover {
  color: #fff;
  background-color: #0d8df6;
  border-color: #0986ed; }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0986ed;
  border-color: #097fe0; }

.btn-secondary.focus, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 102, 85, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655; }

.btn-secondary:hover {
  color: #fff;
  background-color: #fe442f;
  border-color: #fe3822; }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655; }

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fe3822;
  border-color: #fe2d16; }

.btn-success.focus, .btn-success:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-info.focus, .btn-info:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-warning.focus, .btn-warning:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-danger.focus, .btn-danger:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-light.focus, .btn-light:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-dark.focus, .btn-dark:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-outline-primary.focus, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 158, 247, 0.5); }

.btn-outline-primary {
  color: #329ef7;
  background-color: transparent;
  background-image: none;
  border-color: #329ef7; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7; }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #329ef7;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 102, 85, 0.5); }

.btn-outline-secondary {
  color: #fe6655;
  background-color: transparent;
  background-image: none;
  border-color: #fe6655; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655; }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fe6655;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655; }

.btn-outline-success.focus, .btn-outline-success:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-info.focus, .btn-outline-info:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-warning.focus, .btn-outline-warning:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-danger.focus, .btn-outline-danger:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-light.focus, .btn-outline-light:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-dark.focus, .btn-outline-dark:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-link, .btn-link:hover {
  background-color: transparent; }

.btn-link.focus, .btn-link:focus, .btn-link:hover {
  text-decoration: underline;
  border-color: transparent; }

.btn-link {
  color: #329ef7; }

.btn-link:hover {
  color: #0056b3; }

.btn-link.focus, .btn-link:focus {
  box-shadow: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none; }

.btn-lg {
  padding: .5rem 1.625rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.btn-sm {
  padding: .25rem 1.625rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.btn-block {
  display: block; }

.btn-block + .btn-block {
  margin-top: .5rem; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.3125rem 0.875rem 0 rgba(129, 129, 129, 0.2) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: .3rem; }

p {
  color: #8892a0; }

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.fdb-block {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  padding: 7.5rem 0;
  position: relative;
  background-size: cover;
  background-color: #FFF; }

.fdb-block .container {
  background-size: contain; }

.fdb-block .container.bg-r {
  background-position: right; }

.fdb-block.fdb-viewport, .fdb-block.fdb-viewport .container {
  min-height: calc(100% - 2 * 7.5rem); }

.fdb-block.fdb-image-bg {
  color: #f2f2f2; }

.fdb-block .fdb-box {
  background: #FFF;
  padding: 3.75rem 2.5rem;
  overflow: hidden;
  color: #444;
  border-radius: .25rem;
  box-shadow: 0 0.3125rem 0.875rem 0 rgba(129, 129, 129, 0.2) !important; }

.fdb-block .col-fill-left, .fdb-block .col-fill-right {
  width: 50%;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center; }

.fdb-block .fdb-touch {
  border-top: solid .3125rem #329ef7;
  z-index: 2; }

.fdb-block img + h3, .fdb-block img + h4, .fdb-block img + p {
  margin-top: 1.25rem; }

.fdb-block img + h1, .fdb-block img + h2 {
  margin-top: 2.5rem; }

.fdb-block .col-fill-left {
  position: absolute;
  left: 0;
  z-index: 1; }

.fdb-block .col-fill-left + div {
  position: relative;
  z-index: 2; }

.fdb-block .col-fill-right {
  position: absolute;
  right: 0;
  z-index: 1; }

.fdb-block .col-fill-right + div {
  position: relative;
  z-index: 2; }

.fdb-block img.fdb-icon, .fdb-block img.fdb-icon-round {
  width: auto;
  width: 3.75rem; }

.fdb-block .row-100 {
  height: 6.25rem;
  width: 100%; }

.fdb-block .row-50 {
  height: 3.125rem;
  width: 100%; }

.fdb-block .row-70 {
  height: 4.375rem;
  width: 100%; }

footer {
  padding: 1.875rem 0 !important; }

footer.footer-large {
  padding: 6.25rem 0 !important; }

footer .flex-column .nav-link {
  padding-left: 0; }

footer.bg-dark {
  background: #2d313c;
  color: #f2f2f2; }

footer.bg-dark a {
  color: #f2f2f2; }

footer a {
  color: #444; }

header {
  background-color: #FFF; }

header .navbar-nav a.nav-link {
  color: #777; }

header .navbar-nav a.nav-link:hover {
  color: #329ef7; }

header .navbar-nav .active a.nav-link {
  color: #444; }

header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

header.bg-dark .navbar-nav a.nav-link {
  color: #dcdcdc; }

header.bg-dark .navbar-nav a.nav-link:hover {
  color: #329ef7; }

header.bg-dark .navbar-nav .active a.nav-link {
  color: #FFF; }

header.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

header + header {
  border-top: solid .0625rem #EEE; }

.bg-dark {
  background-color: #2d313c;
  color: #f2f2f2; }

.bg-dark p.lead {
  color: #fff; }

.bg-gray {
  background-color: #f4f7fe;
  color: #444; }

.btn {
  border-radius: 50px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700; }
  .btn-primary {
    background: #2E6839;
    border-color: #2E6839; }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
      background: #26562f !important;
      border-color: #26562f !important;
      color: white !important; }
  .btn-outline-primary {
    border-color: #2E6839;
    color: #2E6839; }
    .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
      background: #26562f !important;
      border-color: #26562f !important;
      color: white !important; }
  .btn-secondary {
    background: white;
    color: #2E6839;
    border-color: white; }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
      background: #f2f2f2 !important;
      border-color: #f2f2f2 !important;
      color: #2E6839 !important;
      box-shadow: 0 0 0 0.2rem rgba(37, 119, 36, 0.5) !important; }
  .btn-outline-secondary {
    background: transparent;
    color: white;
    border-color: white; }
    .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
      background: rgba(0, 0, 0, 0) !important;
      border-color: #f2f2f2 !important;
      color: white !important;
      box-shadow: 0 0 0 0.2rem rgba(37, 119, 36, 0.5) !important; }

.btn-reset {
  background: none !important;
  color: inherit !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  cursor: pointer !important;
  outline: none !important;
  box-shadow: none !important; }
  .btn-reset:hover, .btn-reset:focus, .btn-reset:active {
    outline: none !important; }

body {
  font-family: 'Lato', sans-serif; }
  body .grecaptcha-badge {
    visibility: hidden; }

ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none; }

a {
  color: #231f20;
  outline: 0; }
  a:hover, a:focus {
    text-decoration: underline;
    color: #231f20; }

h1 {
  font-size: calc(28px + 10 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h1 {
      font-size: 28px; } }
  @media screen and (min-width: 1920px) {
    h1 {
      font-size: 38px; } }

h2 {
  font-size: calc(26px + 6 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h2 {
      font-size: 26px; } }
  @media screen and (min-width: 1920px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-size: calc(24px + 4 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h3 {
      font-size: 24px; } }
  @media screen and (min-width: 1920px) {
    h3 {
      font-size: 28px; } }

h4 {
  font-size: calc(21px + 3 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h4 {
      font-size: 21px; } }
  @media screen and (min-width: 1920px) {
    h4 {
      font-size: 24px; } }

h5 {
  font-size: calc(18px + 2 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h5 {
      font-size: 18px; } }
  @media screen and (min-width: 1920px) {
    h5 {
      font-size: 20px; } }

p {
  font-size: calc(14px + 2 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    p {
      font-size: 14px; } }
  @media screen and (min-width: 1920px) {
    p {
      font-size: 16px; } }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXjeu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
